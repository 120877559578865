import { render, staticRenderFns } from "./AgentUser.vue?vue&type=template&id=041e138c&scoped=true&"
import script from "./AgentUser.vue?vue&type=script&lang=js&"
export * from "./AgentUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041e138c",
  null
  
)

export default component.exports